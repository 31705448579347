<template>
  <div class="content">
    <div class="content__party content__party--between">
      <div class="content__party content__party--start">
        <router-link
          :to="{ name: 'contacts-main' }"
          class="button button--light button--no-indent button--small"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <h1 class="title title--big title--color">Создание контакта</h1>
      </div>
    </div>
    <form class="form" @submit.prevent="checkForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group" :class="{ 'form-group--error': $v.form.address.$error }">
            <label>Поиск адреса <sup>*</sup></label>
            <v-select
              @input="setValues"
              label="address"
              v-model="form.address"
              :filterable="false"
              :options="addressOptions"
              @search="onAddressSearch"
              :get-option-label="getLabel"
              placeholder="Введите свой адрес"
              class="select"
            >
              <template slot="open-indicator">
                <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none">
                  <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
              </template>
              <template slot="no-options">Введите свой адрес..</template>
              <template slot="option" slot-scope="option">
                <div class="select__item d-center">{{ option.address }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">{{ option.address }}</div>
              </template>
            </v-select>
            <div class="form-group__error" v-if="$v.form.address.$dirty && !$v.form.address.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="region">Регион</label>
            <div class="form-control">
              <input
                id="region"
                type="text"
                name="region"
                class="form-control__input"
                v-model.trim="form.region"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="district">Район</label>
            <div class="form-control">
              <input
                id="district"
                type="text"
                name="district"
                class="form-control__input"
                v-model.trim="form.district"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="city">Город / н.п.</label>
            <div class="form-control">
              <input
                id="city"
                type="text"
                name="city"
                class="form-control__input"
                v-model.trim="form.city"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="street">Улица</label>
            <div class="form-control">
              <input
                id="street"
                type="text"
                name="street"
                class="form-control__input"
                v-model.trim="form.street"
                readonly
              />
            </div>
          </div>
          <div class="form-group">
            <label for="house">Дом</label>
            <div class="form-control">
              <input
                id="house"
                type="text"
                name="house"
                class="form-control__input"
                placeholder="Введите номер Вашего дома"
                v-model.trim="form.house"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="latitude">Координаты: Широта <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.geo_lat.$error }">
              <input
                id="latitude"
                type="text"
                name="latitude"
                class="form-control__input"
                placeholder="Введите широту"
                v-model.trim="form.geo_lat"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.geo_lat.$dirty && !$v.form.geo_lat.required">Обязательное поле</div>
          </div>
          <div class="form-group">
            <label for="longitude">Координаты: Долгота <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.geo_lon.$error }">
              <input
                id="longitude"
                type="text"
                name="longitude"
                class="form-control__input"
                placeholder="Введите долготу"
                v-model.trim="form.geo_lon"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.geo_lon.$dirty && !$v.form.geo_lon.required">Обязательное поле</div>
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label>Телефон <sup>*</sup></label>
            <vue-tel-input
              :value="phoneValue"
              defaultCountry="RU"
              mode="international"
              type="tel"
              class="phonepicker"
              :class="{ 'phonepicker--error': !isPhoneValid }"
              @validate="validedPhone"
              disabledFetchingCountry
              validCharactersOnly
              dynamicPlaceholder
              autocomplete="phone_autocomplete_off"
            >
              <template slot="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="7" fill="none">
                  <path
                    d="M4.96 6.75L.23 1.47a.93.93 0 010-1.22.71.71 0 011.09 0L5.5 4.92 9.68.25a.71.71 0 011.1 0c.3.34.3.88 0 1.22L6.03 6.75A.73.73 0 015.5 7c-.2 0-.4-.08-.54-.25z"
                    fill="#2B93E7"></path>
                </svg>
              </template>
            </vue-tel-input>
            <div class="form-group__error" v-if="$v.form.phone.$dirty && !$v.form.phone.required">Обязательное поле</div>
            <div class="form-group__error" v-if="!isPhoneValid">Недопустимое значение для номера телефона</div>
          </div>
          <div class="form-group">
            <label for="email">Электронная почта <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.email.$error }">
              <input
                id="email"
                type="email"
                name="email"
                class="form-control__input"
                placeholder="Введите Ваш email"
                v-model.trim="form.email"
                @change="$v.form.email.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.email.$dirty && !$v.form.email.email">
              Введите правильный формат почты
            </div>
          </div>
          <div class="form-group">
            <label for="site">Сайт <sup>*</sup></label>
            <div class="form-control" :class="{ 'form-control--error': $v.form.site.$error }">
              <input
                id="site"
                type="url"
                name="site"
                class="form-control__input"
                placeholder="Введите адрес Вашего сайта"
                v-model.trim="form.site"
                @change="$v.form.site.$touch()"
              />
            </div>
            <div class="form-group__error" v-if="$v.form.site.$dirty && !$v.form.site.required">Обязательное поле</div>
            <div class="form-group__error" v-if="$v.form.site.$dirty && !$v.form.site.url">
              Введите правильный формат ссылки
            </div>
          </div>
          <div class="form-group">
            <label for="whatsapp">WhatsApp</label>
            <div class="form-control">
              <input
                id="whatsapp"
                type="text"
                name="whatsapp"
                class="form-control__input"
                placeholder="Введите Ваш WhatsApp"
                v-model.trim="form.whatsapp"
              />
            </div>
          </div>
          <div class="form-group">
            <label for="telegram">Telegram</label>
            <div class="form-control">
              <input
                id="telegram"
                type="text"
                name="telegram"
                class="form-control__input"
                placeholder="Введите Ваш Telegram"
                v-model.trim="form.telegram"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" ref="submit" :disabled="$v.form.$error">
          <span>Добавить</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input';
import {required, email, url} from 'vuelidate/lib/validators';
import {validationMixin} from 'vuelidate';
import { debounce } from 'lodash';

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      email: {required, email},
      phone: {required},
      site: {required, url},
      address: {required},
      geo_lon: {required},
      geo_lat: {required},
    }
  },
  components: {
    VueTelInput,
  },
  data() {
    return {
      form: {
        phone: '',
      },
      phoneValue: '',
      isPhoneValid: true,
      addressOptions: [],
    };
  },
  methods: {
    checkForm() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        });
      } else {
        this.sendForm();
      }
    },
    sendForm() {
      const data = {
        ...this.form,
        address: this.form.address.address,
      };
      this.$refs.submit.classList.add('preload');
      this.$store.dispatch('admin/contacts/POST_MAIN_DATA', data)
        .then(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Контакт успешно создан'
          });
          this.$router.push({ name: 'contacts-main' });
        })
        .catch(response => {
          this.$refs.submit.classList.remove('preload');
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    validedPhone(number) {
      if (!number.number.input) return;
      this.isPhoneValid = number.valid;
      if (number.valid) {
        this.form.phone = number.number.e164;
      } else {
        this.form.phone = null;
      }
    },
    onAddressSearch (search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/company/SEARCH_ADDRESS', {search}).then(response => {
        vm.addressOptions = response.data;
        loading(false);
      });
    }, 350),
    setValues() {
      this.form.region = this.form.address?.region;
      this.form.district = this.form.address?.district;
      this.form.city = this.form.address?.city;
      this.form.street = this.form.address?.street;
      this.form.house = this.form.address?.house;
      this.form.post_index = this.form.address?.post_index;
      this.form.geo_lat = this.form.address?.geo_lat;
      this.form.geo_lon = this.form.address?.geo_lon;
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
  },
};
</script>
